<template>
  <div>
    <ModalAddSubscription
      v-if="showAddSubscription"
      :showModal="showAddSubscription"
      :channelId="selectedChannelId"
      :onClickCancel="hideModal"
      @subscriptionAdded="addSubscription"
    />

    <ModalEditSubscription
      v-if="showEditSubscription"
      :showModal="showEditSubscription"
      :subscription="selectedSubscription"
      :onClickCancel="hideModal"
      @subscriptionUpdated="updateSubscription"
    />

    <ModalDeleteSubscription
      v-if="showDeleteSubscription"
      :showModal="showDeleteSubscription"
      :subscription="selectedSubscription"
      :onClickCancel="hideModal"
      @subscriptionDeleted="removeSubscription"
    />

    <div>
      <MonthYearSelector
        @monthYearSelected="monthYearSelected"
        :isLoadingLeft="isLoadingLeft"
        :isLoadingRight="isLoadingRight"
        :month="Number(month)"
        :year="Number(year)"
      />

      <div
        class="box"
        v-for="(channel, index) in channels.filter(
          (c) => c.ChannelId === channelId
        )"
        :key="index"
      >
        <ui-section-header
          :showAdd="true"
          :addFunction="() => setShowAddSubscription(channel.ChannelId)"
        >
          <template v-slot:title>{{ channel.ChannelName }}</template>
        </ui-section-header>

        <table class="table is-fullwidth is-striped">
          <thead>
            <th width="250">Package</th>
            <th>Period</th>
            <th class="has-text-right">License fee</th>
            <th class="has-text-right">Billable</th>
            <th class="has-text-right">Free trial</th>
            <th class="has-text-right">Recurring</th>
            <th width="100" class="has-text-right">&nbsp;</th>
          </thead>

          <tbody>
            <tr
              v-for="(subscription,
              subscriptionIndex) in getChannelFilteredSubscriptions(
                channel.ChannelId
              )"
              :key="subscriptionIndex"
            >
              <td>{{ subscription.PackageName }}</td>
              <td>
                {{ subscription.Month | getMonthName }} {{ subscription.Year }}
              </td>
              <td class="has-text-right">
                {{ subscription.Price | toCurrency }}
              </td>
              <td class="has-text-right">
                <a @click="changeBillable(subscription)">
                  <span class="has-icon">
                    <span class="icon">
                      <font-awesome-icon
                        :key="'si' + subscription.SendInvoice"
                        :icon="[
                          'fas',
                          subscription.SendInvoice ? 'check' : 'times',
                        ]"
                        :class="
                          subscription.SendInvoice
                            ? 'has-text-success'
                            : 'has-text-danger'
                        "
                      />
                    </span>
                  </span>
                </a>
              </td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    :icon="['fas', subscription.IsTrial ? 'check' : 'times']"
                    :class="
                      subscription.IsTrial
                        ? 'has-text-success'
                        : 'has-text-danger'
                    "
                  />
                </span>
              </td>
              <td class="has-text-right">
                <span class="icon">
                  <font-awesome-icon
                    :icon="[
                      'fas',
                      subscription.IsRecurring ? 'check' : 'times',
                    ]"
                    :class="
                      subscription.IsRecurring
                        ? 'has-text-success'
                        : 'has-text-danger'
                    "
                  />
                </span>
              </td>
              <td width="75" class="has-text-right">
                <div class="field is-grouped">
                  <p class="control">
                    <a
                      v-if="!subscription.IsCancelled"
                      @click="cancelSubscription(subscription)"
                      title="Stop subscription"
                    >
                      <span class="icon has-text-danger">
                        <font-awesome-icon :icon="['fas', 'stop']" />
                      </span>
                    </a>
                    <a
                      v-if="subscription.IsCancelled"
                      @click="reactivateSubscription(subscription)"
                      title="Start recurring subscription"
                    >
                      <span class="icon has-text-success">
                        <font-awesome-icon :icon="['fas', 'play']" />
                      </span>
                    </a>
                  </p>

                  <p class="control">
                    <a
                      @click="setShowEditSubscription(subscription)"
                      title="Edit subscription"
                    >
                      <span class="icon">
                        <font-awesome-icon :icon="['fas', 'edit']" />
                      </span>
                    </a>
                  </p>

                  <p class="control">
                    <a
                      @click.exact="setShowDeleteSubscription(subscription)"
                      @click.ctrl="instantDelete(subscription)"
                      title="Delete subscription"
                    >
                      <span class="icon has-text-danger">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { EventBus } from '@/eventbus/event-bus'
import { mapActions, mapState, mapGetters } from 'vuex'
import subscriptionProvider from '@/providers/subscription'

const ModalAddSubscription = () =>
  import('@/components/Locations/ModalAddLocationSubscription')
const ModalEditSubscription = () =>
  import('@/components/Locations/ModalEditLocationSubscription')
const ModalDeleteSubscription = () =>
  import('@/components/Locations/ModalDeleteLocationSubscription')
const MonthYearSelector = () => import('@/components/UI/MonthYearSelector')

export default {
  components: {
    ModalAddSubscription,
    ModalEditSubscription,
    ModalDeleteSubscription,
    MonthYearSelector,
  },

  data() {
    return {
      channelId: this.$route.params.channelId
        ? Number(this.$route.params.channelId)
        : 0,
      locationId: this.$route.params.locationId
        ? Number(this.$route.params.locationId)
        : 0,
      isLoading: false,
      isLoadingLeft: false,
      isLoadingRight: false,

      itemsPerPage: 12,
      month: 0,
      packageId: 0,
      searchTerm: '',

      page: 0,
      year: new Date().getFullYear(),
      selectedChannelId: 0,
      selectedPackage: null,
      showAddSubscription: false,
      showEditSubscription: false,
      showDeleteSubscription: false,
      subscriptions: [],
      selectedSubscription: null,
      timestamp: new Date().getTime(),
    }
  },

  computed: {
    ...mapState('locationStore', ['locationState']),

    ...mapGetters({
      isInitiated: 'getIsInitiated',
      isLoggedIn: 'profileStore/getIsLoggedIn',
      profile: 'getProfile',
      profileToken: 'profileStore/getProfileToken',
    }),

    channels() {
      let chn = []

      if (this.locationState.Channels.length > 0) {
        for (let i = 0; i < this.locationState.Channels.length; i++) {
          chn.push(this.locationState.Channels[i])
        }
      }

      if (this.subscriptions && this.subscriptions.length > 0) {
        for (let j = 0; j < this.subscriptions.length; j++) {
          let subscription = this.subscriptions[j]
          let channelIndex = chn.findIndex(
            (c) => c.ChannelId === subscription.ChannelId
          )
          if (channelIndex === -1) {
            chn.push({
              ChannelId: subscription.ChannelId,
              ChannelName: subscription.ChannelName,
            })
          }
        }
      }

      return chn
    },
  },

  created() {
    this.getLocationSubscriptions()
  },

  methods: {
    ...mapActions('subscriptionStore', [
      'deleteSubscription',
      'editSubscription',
    ]),

    getLocationSubscriptions() {
      this.subscriptions = []

      subscriptionProvider.methods
        .getSubscriptions(
          this.channelId,
          this.locationId,
          this.month,
          this.year,
          this.packageId,
          this.searchTerm
        )
        .then((response) => {
          if (response.status === 200) {
            this.subscriptions = response.data
          }
        })
        .catch((e) => {})
        .finally(() => {
          this.isLoadingLeft = false
          this.isLoadingRight = false
        })
    },

    getChannelFilteredSubscriptions(channelId) {
      return this.subscriptions.filter((s) => s.ChannelId === channelId)
    },

    monthYearSelected(data) {
      let self = this

      self.month = Number(data.month)
      self.year = Number(data.year)

      self.isLoadingLeft = data.direction === 'left'
      self.isLoadingRight = data.direction === 'right'

      self.getLocationSubscriptions()
    },

    goToInvoice(invoiceId) {
      this.$router.push({ path: `/invoice/${invoiceId}` })
    },

    instantDelete(subscription) {
      let self = this

      self
        .deleteSubscription(subscription)
        .then((response) => {
          if (response.status === 204) {
            let index = this.subscriptions.findIndex(
              (s) => s.Id === subscription.Id
            )

            if (index > -1) {
              this.subscriptions.splice(index, 1)
            }

            // Send message to display toast on screen
            EventBus.$emit('showToast', {
              type: 'info',
              message: 'Subscription deleted!',
            })
          }
        })
        .finally(() => {
          self.isDeleting = false
        })
    },

    changeBillable(subscription) {
      if (subscription.SendInvoice) {
        this.setNotBillable(subscription)
      } else {
        this.setBillable(subscription)
      }
    },

    setNotBillable(subscription) {
      subscription.SendInvoice = false
      this.editSubscription(subscription)

      this.updateSubscription(subscription)
    },

    setBillable(subscription) {
      subscription.SendInvoice = true
      this.editSubscription(subscription)

      this.updateSubscription(subscription)
    },

    addSubscription(subscription) {
      this.subscriptions.push(subscription)
    },

    updateSubscription(subscription) {
      let index = this.subscriptions.findIndex((s) => s.Id === subscription.Id)

      if (index > -1) {
        Vue.set(this.subscriptions, index, subscription)
      }
    },

    removeSubscription(subscription) {
      let index = this.subscriptions.findIndex((s) => s.Id === subscription.Id)

      if (index > -1) {
        this.subscriptions.splice(index, 1)
      }
    },

    resetAddSubscription() {
      this.channelId = 0
      this.selectedPackage = null
      this.newMonth = new Date().getMonth()
      this.newYear = new Date().getFullYear()
      this.showAddSubscription = false
    },

    extendSubscription(value) {
      subscriptionProvider.methods
        .extendSubscription(value)
        .then((response) => {
          this.getLocationSubscriptions()
        })
    },

    cancelSubscription(subscription) {
      subscription.IsCancelled = true
      subscription.IsRecurring = false

      this.editSubscription(subscription)
      this.resetAddSubscription()

      this.updateSubscription(subscription)
    },

    reactivateSubscription(subscription) {
      subscription.IsCancelled = false
      subscription.IsRecurring = true

      this.editSubscription(subscription)

      this.updateSubscription(subscription)
    },

    setShowAddSubscription(channelId) {
      this.showAddSubscription = true
      this.selectedChannelId = channelId
    },

    setShowEditSubscription(subscription) {
      this.showEditSubscription = true
      this.selectedSubscription = subscription
    },

    setShowDeleteSubscription(subscription) {
      this.showDeleteSubscription = true
      this.selectedSubscription = subscription
    },

    hideModal() {
      this.showAddSubscription = false
      this.showEditSubscription = false
      this.showDeleteSubscription = false

      this.selectedChannelId = 0
      this.selectedSubscription = null
    },
  },
}
</script>

<style lang="scss" scoped></style>
